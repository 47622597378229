'use strict';

angular.module('enervexSalesappApp').controller('LoginCtrl', function($scope, Auth, Flash, Account,$stateParams, $state) {
	$scope.user = {};
	$scope.errors = {};
	console.log("redirect is"+$stateParams.redirect)

	if ($stateParams.email){
		$scope.user.email = $stateParams.email
	}
	$scope.gotoSignup = function(e){
		e.preventDefault()
		$state.go("signup", {
			redirect: $stateParams.redirect
		}, {
			reload: true,
			inherit: false
		})
	}
	$scope.login = function(form) {
		$scope.submitted = true;
		$scope.success = false;

		if (form.$valid) {
			Auth.login({
				email: $scope.user.email,
				password: $scope.user.password
			}).then(function(response) {
				$scope.success = true;
				if ($stateParams.redirect){
					window.location.href=$stateParams.redirect;
				} else if (response.redirect){
					console.log("success redirecting to "+response.redirect);
					window.location.href=response.redirect;
				} else {
					Account.query().$promise.then(function(res){
						$scope.accounts = res;
						if( $scope.accounts.length != 1) {
							window.location.href="/accounts"
						} else {
							var accountId = $scope.accounts[0]._id;
							window.location.href= "/accounts/" + accountId;
						}
					});
				}
			}).catch(function(err) {
				
				$scope.errors.other = err.message;
				var message = err.data.message || err.statusText;
				Flash.create('danger', '<strong>Error!</strong> '+message, 0, {}, true);
			});
		}
	};
});
